import m from 'mithril';

import { Cite } from './components/Cite';
import { Opinion } from './components/Opinion';
import { Account } from './components/Account';
import { OpinionPageStore } from './models/OpinionStore';
import { Judge } from './models/Judge';

if (['howtruthful.com', 'www.howtruthful.com'].includes(window.location.hostname)) {
    window.location.href = `https://en.howtruthful.com${window.location.pathname}`;
}

const judge = new Judge();

let store = new OpinionPageStore('');
store.judge = judge;
const loc = localStorage.getItem('o');
if (loc) {
    judge.k = () => Math.ceil(loc.length / 1024);
    store.overlay(JSON.parse(loc));
    store.clearPendingSave();
}

addEventListener('storage', (event: StorageEvent) => {
    if (event.key === 'o' && event.newValue) {
        store.overlay(JSON.parse(event.newValue));
        m.redraw();
    }
});

const AppO = {
    view: () => <Opinion judge={judge} store={store} />
};

const AppC = {
    view: () => <Cite judge={judge} store={store} />
};

const AppI = {
    view: () => <Account judge={judge} store={store} />
};

m.route.prefix = '';

m.route(document.getElementById('mainApp'), '/o/', {
    '/o/': AppO,
    '/o/:opinionPath/:hash': AppO,
    '/c/': AppC,
    '/i/': AppI
});
