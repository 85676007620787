import m from 'mithril';
import { MithrilTsxComponent } from 'mithril-tsx-component'

export class Pay extends MithrilTsxComponent<{}> {
    view() {
        return (
            <div class="middle" >
                <p>You may use HowTruthful to store opinions and evidence privately on your own computer or phone for free. To upload them to our servers will cost USD $10 per year. Yes, year, not per month. See the Introduction video link below for reasons why payment is required.</p>

                <form action="/paid" method="POST">
                    <script
                        src="https://checkout.stripe.com/checkout.js" class="stripe-button"
                        data-key={/howtruthful/.test(location.hostname) ? 'pk_live_yA0qBs6SdQLUMrsYxPZw20nL' : 'pk_test_M65M15lpr1ENIFWLNgl29sW1'}
                        data-amount="1000"
                        data-name="howtruthful"
                        data-description="1 Year"
                        data-image="https://stripe.com/img/documentation/checkout/marketplace.png"
                        data-locale="auto"
                        data-zip-code="true">
                    </script>
                </form>
            </div>);
    }
};
