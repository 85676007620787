import AccountBox from '@mithril-icons/material-design/icons/AccountBox';
import LibraryVideo from '@mithril-icons/material-design/icons/LibraryVideo';
import PlusBox from '@mithril-icons/material-design/icons/PlusBox';
import Star from '@mithril-icons/material-design/icons/Star';
import m from 'mithril';
import { MithrilTsxComponent } from 'mithril-tsx-component';
import { Judge } from '../models/Judge';
import { OpinionPageStore } from '../models/OpinionStore';
const { Link } = m.route;

export interface ITruthAttrs {
    judge: Judge;
    store: OpinionPageStore;
}

export type TruthVnode<T> = m.Vnode<ITruthAttrs, TruthPage<T>>
export type TruthVnodeDOM<T> = m.VnodeDOM<ITruthAttrs, TruthPage<T>>

export class TruthPage<T> extends MithrilTsxComponent<T> {
    currentTool: string = '';
    userMedia: MediaStream;
    judge: Judge;
    store: OpinionPageStore;
    headerTitle(): any {
        return '';
    };
    oninit(v: TruthVnodeDOM<T>) {
        this.judge = v.attrs.judge || new Judge();
        this.store = v.attrs.store;
        m.request({
            method: 'GET',
            url: '/d/i',
            params: this.judge.k ? { k: this.judge.k() } : undefined
        }).then((user: Object) => {
            Object.assign(this.judge, user);
            if (this.judge.bearer) {
                this.store.receiveTopLevelOpinions();
            }
        });
    }
    viewHeader(v: TruthVnode<T>) {
        return (<header>
            <h1><a href="/">How Truthful?</a></h1>
            <h2>{v.state.headerTitle()}</h2>
        </header >);
    };
    view(v: TruthVnode<T>) {
        return [
            this.viewHeader(v),
            this.viewMain(v)
        ];
    };
    viewMain(v: TruthVnode<T>) {
        return <main>{this.viewNav(v)}</main>;
    };
    viewNav(v: TruthVnode<T>) {
        const loginLink = () =>
            v.state.judge?.bearer ? '/i/' :
                `/i/?next=${encodeURIComponent(location.pathname)}`;

        return (<nav class="toolbar" >
            <Link href="/o/" class={this.currentTool === 'opinion' ? 'current' : ''} title="Type an opinion to create a new one or find an old one">
                <PlusBox />
                <span>Opinion</span>
            </Link>
            <Link target="youtube" href="https://www.youtube.com/watch?v=WA_QNYOSn9g&list=PL69AQIjSnMAKKgGSuS5ICs4vMqZyxW7mq" title="Watch an introductory video">
                <LibraryVideo />
                <span>Intro</span>
            </Link>
            <Link rel="nofollow" href={loginLink()} class={this.currentTool === 'account' ? 'current' : ''}>
                {v.state.judge?.hasPremium() ? <Star /> : <AccountBox />}
                <span>{v.state.judge?.bearer ? 'Account' :
                    'Sign In'}</span>
            </Link>
        </nav>);
    };
}
