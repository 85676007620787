import classNames from 'classnames';
import m from 'mithril';
import { MithrilTsxComponent } from 'mithril-tsx-component';
import { OpinionPageStore, ratings } from '../models/OpinionStore';
const { Link } = m.route;

export interface IRatingAttrs {
    store: OpinionPageStore;
    rating: number;
}

type Vnode = m.Vnode<IRatingAttrs, Rating>
type VnodeDOM = m.VnodeDOM<IRatingAttrs, Rating>

export class Rating extends MithrilTsxComponent<IRatingAttrs> {
    radio: HTMLInputElement;
    rating: number;
    store: OpinionPageStore;
    oninit(v: Vnode) {
        this.rating = v.attrs.rating;
        this.store = v.attrs.store;
    };
    oncreate(v: VnodeDOM) {
        this.radio = v.dom.querySelector('input[type=radio]');
    }
    updRating() {
        this.store.setUserRating(this.store.getMain(), this.rating);
        this.store.save();
        this.radio.focus();
    };
    view(v: Vnode) {
        const { store, rating } = v.attrs;
        const userRating = store.getUserRating(store.getMain());
        const isUserRating = (rating === userRating);
        const isOwnerRating = (rating === store.getMain().ownerRating);
        const desc = ratings[rating].description;
        let youLink = '';
        if (!store.isMine(store.getMain()) && isUserRating) {
            youLink = store.getMine(store.getMain()).getFullPath();
        }

        return (
            <div class={classNames({ user: isUserRating, owner: isOwnerRating })} onclick={this.updRating.bind(this)}>
                {youLink && <Link class="you" href={youLink}>You</Link>}
                <dt class={'t' + rating}>{ratings[rating].badge}</dt>
                <dd>
                    <label class={classNames({ long: desc.length > 10 })}>{desc}</label>
                    <input type="radio" checked={isUserRating} name="rating" value={rating} />
                </dd>
            </div>
        );
    }
}
