import m from 'mithril';
import { ITruthAttrs, TruthVnode, TruthPage } from './TruthPage';
import { Judge } from '../models/Judge';
import { Pay } from './Pay';
import { OpinionPageStore, OpinionStore } from '../models/OpinionStore';
import { Opinion, RelatedLink } from './Opinion';

export interface IOpinionAttrs extends ITruthAttrs {
}

export class Account extends TruthPage<IOpinionAttrs> {
    currentTool = 'account';
    signout(v: TruthVnode<IOpinionAttrs>) {
        const bearer = v.state.judge.bearer;
        return (e: Event) => {
            m.request({
                method: 'POST',
                url: '/d/i0',
                body: { bearer }
            }).then(result => {
                if (result) {
                    v.state.judge.logout();
                }
            }).catch(e => {
                console.log(e);
                v.state.judge.logout();
            });
            return false;
        };
    };
    headerTitle = () => 'Account';
    viewMain(v: TruthVnode<IOpinionAttrs>) {
        document.title = 'Account';
        const topLevel = v.attrs.store.opinions &&
            Array.from(
                new Set(
                    Object.values(v.attrs.store.opinions).filter(
                        (o: OpinionStore) =>
                            !o.parents.length &&
                            o.statement &&
                            v.attrs.store.isMine(o)).map((o: OpinionStore) => o.id)));

        return (
            <main class="middle">
                <h1>Your HowTruthful account</h1>
                <p>Drag this → <a onclick={e => e.preventDefault()} href={"javascript:((d,i,s)=>{(i&&(d.location.hash=`#${i.id}`)),open(`" + `${document.location.protocol}//${document.location.host}` + "/c/?s=${encodeURIComponent(s||d.title)}&u=${encodeURIComponent(d.location.href)}`)})(document,getSelection().anchorNode&&getSelection().anchorNode.parentNode.closest('*[id]'),getSelection().toString()),undefined"}>Cite</a> ← bookmarklet to your bookmarks bar if you think you might use HowTruthful to evaluate statements on the web.</p>
                {!this.judge.bearer &&
                    <div>
                        <h2>Choose one of the following ways to sign in:</h2>
                        <p><a href={`/login/twitter${location.search}`}>Sign in with Twitter.</a></p>
                        <p><a href={`/login/facebook${location.search}`}>Sign in with Facebook.</a></p>
                    </div>}
                {this.judge && this.judge.hasPremium() &&
                    <div><p>Days left of premium: {this.judge.premium}. Thanks for supporting this effort. Feel free to email <a href="mailto:howtruthful@googlegroups.com">howtruthful@googlegroups.com</a> with comments or questions.</p></div>}

                {this.judge.bearer && !this.judge.hasPremium() && <Pay />}

                {(topLevel?.length || '') &&
                    <div class="related">
                        <h2>Your top-level opinions</h2>
                        <ul>
                            {topLevel.map((key: string) =>
                                <RelatedLink key={v.attrs.store.opinions[key].id} opinion={v.attrs.store.opinions[key]} />)}
                        </ul>
                    </div>
                }

                {this.judge.bearer &&
                    <div>
                        <a href="#" onclick={this.signout(v)}>Sign out</a>
                    </div>
                }
                <p><a href="https://en.howtruthful.com/o/as_of_2023-11-03_howtruthful_has_a_privacy_policy/2f9ee413241176a6f3a635c470bd7695">Privacy</a>, <a href="https://en.howtruthful.com/o/as_of_2019-11-25_use_of_howtruthful_constitutes_agreement_to_certain_terms_of_service/554e40372f25198da367a607d1844cf8">Terms of Service</a> </p>

                {this.viewNav(v)}
            </main>
        );
    }
}
