import m from 'mithril';
import { MithrilTsxComponent } from 'mithril-tsx-component'
import { pubs, OpinionPageStore } from '../models/OpinionStore';
import { Judge } from '../models/Judge';
import classNames from 'classnames';

export interface IPubAttrs {
    store: OpinionPageStore;
    judge: Judge;
}

type Vnode = m.Vnode<IPubAttrs, Pub>

export class Pub extends MithrilTsxComponent<IPubAttrs> {
    store: OpinionPageStore;
    expanded: boolean;
    judge: Judge;
    oninit(v: Vnode) {
        this.store = v.attrs.store;
        this.judge = v.attrs.judge;
        this.expanded = false;
    };
    updPub(event: Event) {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
            this.store.getMain().setPub(parseInt(target.value), this.judge);
            this.store.save();
        }
    };
    view(v: Vnode) {
        const pub = v.attrs.store.getMain().pub || 1;
        const desc = m.trust(pubs[pub].description(this.store, v.attrs.judge));
        return (
            <p class="pub">
                private {[1, 4].map((i: number) => !i ? '' :
                    <input type="radio" checked={pub === i} name="pub" value={i} onchange={this.updPub.bind(this)} />)} {v.attrs.judge && v.attrs.judge.hasPremium() ? 'public' : <s>public</s>} <br />
                {desc}
            </p>
        );
    }
}
