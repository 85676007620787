import m from 'mithril';
import { MithrilTsxComponent } from 'mithril-tsx-component'
import { pubs, ratings, OpinionStore, OpinionPageStore } from '../models/OpinionStore';
import { ITruthAttrs, TruthPage } from './TruthPage';
import classNames from 'classnames';

export interface ICiteAttrs extends ITruthAttrs {
    store: OpinionPageStore;
}

type Vnode = m.Vnode<ICiteAttrs, Cite>

export class Cite extends TruthPage<ICiteAttrs> {
    headerTitle = () => 'Cite Web Source';
    viewMain(v: Vnode) {
        const params = new URLSearchParams(document.location.search);
        const statement = params.get('s');
        const url = params.get('u');

        return (
            <main class="cite">
                <p>
                    <form onsubmit={submitHandler(v.attrs.store)}>
                        <input name="statement" class="wide" required type="text" value={statement} placeholder="Statement" maxlength="255" /><br />
                        <input class="wide" required type="url" value={url} placeholder="URL" maxlength="255" /><br />
                        <input type="submit" value="Cite" />
                        <div>
                            Optional:
                            <input list="statements" class="wide" type="text" name="profor" placeholder="Use as evidence for..." maxlength="255" /><br />
                            <input list="statements" class="wide" type="text" name="confor" placeholder="Use as evidence against..." maxlength="255" /><br />
                        </div>
                    </form>
                </p>
                <datalist id="statements">
                    {v.attrs.store.getAllOpinions().map(stmt => <option value={stmt} />)}
                </datalist>

                {this.viewNav(v)}
            </main>
        );
    }
}

const submitHandler = (store: OpinionPageStore) => {
    const handleSubmit = (event: Event) => {
        event.preventDefault();
        if (event.target instanceof HTMLElement) {
            const statement = event.target.querySelector<HTMLInputElement>('input[name=statement]').value;
            const profor = event.target.querySelector<HTMLInputElement>('input[name=profor]').value;
            const confor = event.target.querySelector<HTMLInputElement>('input[name=confor]').value;
            const url = event.target.querySelector<HTMLInputElement>('input[type=url]').value;
            if (statement && url) {
                if (profor) {
                    store.main = store.add(profor).id;
                    store.addRelated('pro', statement);
                }
                if (confor) {
                    store.main = store.add(confor).id;
                    store.addRelated('con', statement);
                }
                store.main = store.add(statement).id;
                store.addRelated('pro', url);
                if ((store.getMain().pub || 0) < 4) {
                    store.getMain().setPub(4, store.judge);
                }
                m.route.set(
                    store.getMain().getFullPath(),
                    null, { replace: true });
            }
        }
    }
    return handleSubmit;
}
