import Check from '@mithril-icons/material-design/icons/Check';
import DeleteForever from '@mithril-icons/material-design/icons/DeleteForever';
import Pencil from '@mithril-icons/material-design/icons/Pencil';
import classNames from 'classnames';
import m from 'mithril';
import { MithrilTsxComponent } from 'mithril-tsx-component';
import { OpinionPageStore, OpinionStore, pubs, ratings } from '../models/OpinionStore';
import { Pub } from './Pub';
import { Rating } from './Rating';
import { ITruthAttrs, TruthPage } from './TruthPage';
const { Link } = m.route;

const defaultTitle = 'New opinion'

export interface IOpinionAttrs extends ITruthAttrs {
}

type Vnode = m.Vnode<IOpinionAttrs, Opinion>

const isRealBlur = (event: Event) => (event.target !== document.activeElement);

const addRelated = (store: OpinionPageStore, rel: 'pro' | 'con') =>
    (event: Event) => {
        if (isRealBlur(event)) {
            const target = event.target;
            if (target instanceof HTMLInputElement) {
                const val = target.value;
                if (val.trim()) {
                    const [x, y] = [window.scrollX, window.scrollY];
                    store.addRelated(rel, val);
                    target.value = '';
                    setTimeout(() => {
                        window.scrollTo(x, y);
                        target.focus();
                    }, 0);
                    event.preventDefault();
                }
            }
        }
    };

const unlinkRelated = (store: OpinionPageStore, rel: 'pro' | 'con',
    id: string) =>
    (event: Event) => {
        event.preventDefault();
        store.unlinkRelated(rel, id);
    };

export interface IRelatedLinkAttrs {
    editing: boolean;
    opinion: OpinionStore;
    onclick: Function;
}

type RelatedVnode = m.Vnode<IRelatedLinkAttrs, RelatedLink>
export class RelatedLink extends MithrilTsxComponent<IRelatedLinkAttrs> {
    view(v: RelatedVnode) {
        const o = v.attrs.opinion;
        const f = v.attrs.onclick;
        const editing = v.attrs.editing;
        const externalLink = o.isLink() && !editing;

        if (!o) {
            return '';
        }
        const r = o.ownerRating;
        const proCount = o.pro.length;
        const conCount = o.con.length;
        let procon = [proCount && `${proCount}+`, conCount && `${conCount}-`].filter(x => x).join(',');
        if (procon) {
            procon = `&nbsp;<span title="Click the link to see evidence">(${procon})</span>`;
        }

        return (
            <li class={`t${r}`} title={ratings[r].description}>{
                externalLink ?
                    <a href={o.statement}>{o.statement}</a> :
                    <Link href={o.getFullPath()} onclick={(e: Event) => {
                        if (f) {
                            f(e);
                        }
                    }}> {o.statement}</Link>}{m.trust(procon)}{v.children}</li>
        );
    }
}

export class Opinion extends TruthPage<IOpinionAttrs> {
    editing: boolean;
    needsHint: boolean = true;
    getMain(): OpinionStore {
        return this.store.getMain();
    }
    needsHintSetter(val: boolean) {
        return () => {
            this.needsHint = val;
        }
    };
    updStatement = (event: Event) => {
        if (event.target instanceof HTMLInputElement) {
            const statement: string = event.target.value;
            this.store.getMain().setStatement(statement);
            m.route.set(
                this.store.getMain().getFullPath(),
                null, { replace: true });
            this.store.save().then(() => {
                m.route.set(
                    this.store.getMain().getFullPath(),
                    null, { replace: true });
            });
        }
    };
    deleteStatement = (event: Event) => {
        event.preventDefault();
        if (window.confirm(`Really delete “${this.store.getMain().statement}”?`)) {
            this.store.deleteMain();
            this.store.save();
            m.route.set('/o/', null, { replace: true });
        }
    };
    placeholder = () =>
        'Say something ' + ratings[this.getMain().ownerRating].description;
    oninit(v: Vnode) {
        super.oninit(v);
        this.store.setMainFromPath(m.route.param('opinionPath'),
            m.route.param('hash'));
        const { statement, ownerRating } = this.store.getMain();
        document.title = `${ratings[ownerRating].badge} ${(statement || defaultTitle)}`;
        this.editing = !statement;
    };
    headerTitle = () => {
        const main = this.getMainOpinion(this.store);
        if (!main.statement) {
            return defaultTitle;
        }
        const img = this.editing ?
            <Check width="16" height="16" alt="(Done)" /> :
            <Pencil width="16" height="16" alt="(Edit)" />;
        return this.store.isMine(main) &&
            <label>{this.editing ?
                'Stop editing' :
                'Edit this opinion'}
                <button onclick={() => {
                    this.editing = !this.editing
                }}>{img}</button>
            </label>;
    };
    getMainOpinion(store: OpinionPageStore) {
        const opinionPath = m.route.param('opinionPath');
        const hash = m.route.param('hash');
        const id = (hash && hash.length === 32) ? hash : opinionPath;
        if (store.main !== id) {
            store.setMainFromPath(opinionPath, hash);
            this.editing = this.editing && store.isMine(store.getMain());
        }
        const mainOpinion: OpinionStore = store.getMain();
        document.title = `${ratings[mainOpinion?.ownerRating]?.badge} ${mainOpinion?.statement || defaultTitle}`;
        return mainOpinion;
    };
    viewMain(v: Vnode) {
        const store = v.attrs.store;
        const main = this.getMainOpinion(store);
        const mainClass = classNames({
            empty: !main.statement,
            debug: !!store.opinions['debug']
        });
        this.currentTool = classNames({
            opinion: !main.statement
        });
        const whose = this.store.isMine(main) ? 'Your' : "Someone's";
        const opinionDescription = pubs[main.pub || 1].title('you').replace('opinion', 'rating');
        let voteTotal = 0;
        if (main.votes) {
            for (let i = 0; i < main.votes.length; i++) {
                voteTotal += main.votes[i];
            }
        }

        if (!main.statement) {
            this.editing = true;
            document.title = defaultTitle;
        }

        return (
            <main class={mainClass} >
                {this.editing && main.statement && <details>
                    <summary>Editing...</summary>
                    <button class="delete" title="Delete this statement" onclick={this.deleteStatement}>
                        <DeleteForever />
                    </button>
                    <Pub store={store} judge={this.judge} />
                    <p>When done, use the check mark in the upper right corner. To move to the truthfulness rating, press Tab⇥, then arrow keys to adjust. Press Tab⇥ again to add supporting arguments.</p>
                </details>}
                <p class="claim">
                    {this.editing ? <input list="statements" type="text" placeholder={this.placeholder()} onchange={this.updStatement} onfocus={this.needsHintSetter(true)} onblur={this.needsHintSetter(false)} value={main.statement} maxlength="255" /> : <h3>{main.statement}</h3>}
                </p>
                {(this.editing && this.needsHint) ? '' : ''}

                <p>{whose} {opinionDescription} of the truthfulness of the above statement:</p>
                <dl class="ratings">{
                    [1, 2, 3, 4, 5].map(rating => <Rating store={store} rating={rating} />)
                }
                </dl>
                <div class="peanut-gallery">{!voteTotal ? <i>This line reserved for a summary of others' opinions on the same statement.</i> :
                    main.votes.map((num, i) => <span title={`${num}/${voteTotal} say${num === 1 ? 's' : ''} ${ratings[i + 1].description}`} >{!!num && `${Math.round(100 * num / voteTotal)}%`}</span>)} </div>

                <div class="related">
                    <div class="pro">
                        {(this.editing || store.getPro().length > 0) && <h2>Pro</h2>}
                        <ul>
                            {store.getPro().map(o => o && <RelatedLink key={o.id} editing={this.editing} opinion={o} onclick={() => { this.editing = false; }}> {this.editing && <button onclick={unlinkRelated(store, 'pro', o.id)}>unlink</button>}</RelatedLink>)}
                            {this.editing && <li class="t3" title={ratings[3].description}>
                                <input key="1" list="statements" placeholder={`Argue for “${main.statement}”`} onblur={addRelated(store, 'pro')} maxlength="255" />
                            </li>}
                        </ul>
                    </div>
                    <div class="con">
                        {(this.editing || store.getCon().length > 0) && <h2>Con</h2>}
                        <ul>
                            {store.getCon().map(o => <RelatedLink key={o.id} editing={this.editing} opinion={o} onclick={() => { this.editing = false; }}> {this.editing && <button onclick={unlinkRelated(store, 'con', o.id)}>unlink</button>}</RelatedLink>)}
                            {this.editing && <li class="t3" title={ratings[3].description}>
                                <input key="1" list="statements" placeholder={`Argue against “${main.statement}”`} onblur={addRelated(store, 'con')} maxlength="255" />
                            </li>}
                        </ul>
                    </div>
                </div>
                <details class="debug">
                    <summary>debug</summary>
                    <pre class="debug">{JSON.stringify(store, null, 4)}</pre>
                </details>
                <datalist id="statements">
                    {this.store.getAllOpinions().map(stmt => <option value={stmt} />)}
                </datalist>
                {this.viewNav(v)}
            </main>
        );
    }
}
