import m from 'mithril';
import { Judge } from './Judge';
import { Md5 } from 'ts-md5';
import { ServerOpinion } from './ServerOpinion';
export const ratings = [
        {},
        {
                description: 'false',
                badge: '➊'
        },
        {
                description: 'unlikely or mostly false',
                badge: '➋'
        },
        {
                description: 'debatable',
                badge: '➌'
        },
        {
                description: 'likely or mostly true',
                badge: '➍'
        },
        {
                description: 'true',
                badge: '➎'
        },
];

function paid(store: OpinionPageStore, judge: Judge, msg: string) {
        if (judge && judge.hasPremium()) {
                return msg;
        } else {
                return msg + ` <a href="${store.getLoginPath()}">Requires paid account</a>.`;
        }
}

export const pubs = [
        {},
        {
                title: (name: string) => 'private opinion',
                description: () => 'Stored only on this device.'
        },
        {
                title: (name: string) => 'unlisted, anonymous opinion',
                description: (store: OpinionPageStore, judge: Judge) => paid(store, judge, `Not on the web.`)
        },
        {
                title: (name: string) => `unlisted opinion by ${name}`,
                description: (store: OpinionPageStore, judge: Judge) => paid(store, judge, `Not on the web.`)
        },
        {
                title: (name: string) => 'public opinion',
                description: (store: OpinionPageStore, judge: Judge) => paid(store, judge, `On the web.`)
        },
        {
                title: (name: string) => `public opinion by ${name}`,
                description: (store: OpinionPageStore, judge: Judge) => paid(store, judge, `On the web.`)
        }
];

export class OpinionStore {
        statement: string;
        path: string;
        hash: string;
        getFullPath() {
                return `/o/${encodeURIComponent(this.statement.replace(/[\s,\.\/;&\?\"]+/g, '_').toLowerCase())}/${this.hash}`;
        };
        id: string;
        pub: number;
        ownerRating: number;
        owner: string;
        parents: string[];
        pro: string[];
        con: string[];
        votes?: number[];
        s: number;
        was?: string;
        constructor(statement: string) {
                this.setStatement(statement);
                this.ownerRating = 3;
                this.parents = [];
                this.pro = [];
                this.con = [];
                this.hash = 'i';
        };
        isLink() {
                return this.statement.startsWith('http:') ||
                        this.statement.startsWith('https:');
        };
        setStatement(statement: string) {
                const oldId = this.id || '';
                const newStatement = statement.trim().replace(/\s+/g, ' ');
                const newPath = newStatement.replace(/[\s,\.\/;&\?\"]+/g, '_').toLowerCase();
                // FIXME: use hash
                const newId = newPath;
                const p: OpinionPageStore = this.getPage();
                if (p) {
                        if (!newId || !p.opinions[newId]) {
                                this.statement = newStatement;
                                this.path = newPath;
                                this.id = newId;
                                p.opinions[newId] = this;
                        }
                        if (p.main === oldId) {
                                p.main = newId;
                        }
                        if (
                                !oldId ||
                                (newId !== oldId &&
                                        p.opinions[oldId] === p.opinions[newId])
                        ) {
                                delete p.opinions[oldId];
                        }
                        if (oldId && (oldId !== newId)) {
                                if (this.s >= 2) {
                                        this.was = oldId;
                                }
                                this.s = 0;
                                this.hash = 'i';
                                Object.keys(p.opinions).forEach(key => {
                                        const o = p.opinions[key];
                                        for (let i = 0; i < o.pro.length; i++) {
                                                if (o.pro[i] === oldId) {
                                                        o.pro[i] = newId;
                                                }
                                        }
                                        for (let i = 0; i < o.con.length; i++) {
                                                if (o.con[i] === oldId) {
                                                        o.con[i] = newId;
                                                }
                                        }
                                });
                        }
                } else {
                        this.statement = newStatement;
                        this.path = newPath;
                        this.id = newId;
                }
        };
        setPub(pub: number, judge: Judge) {
                if (pub !== this.pub) {
                        const p = this.getPage();
                        const deleteFromServer = p && this.pub > 1 && pub <= 1 && this.hash.length === 32;
                        this.pub = pub;
                        if (p) {
                                p.judge = judge;
                                if (pub > 1) {
                                        this.parents.forEach(id => {
                                                if (p.opinions[id]) {
                                                        p.opinions[id].s = 0;
                                                }
                                        });
                                        this.s = 0;
                                        const recurse = rel => id => {
                                                const opin2 = p.opinions[id];
                                                if (opin2 && (opin2.pub || 1) == 1) {
                                                        opin2.setPub(pub, judge);
                                                }
                                        };
                                        this.pro.forEach(recurse('pro'));
                                        this.con.forEach(recurse('con'));
                                }
                                if (deleteFromServer) {
                                        p.deletions.push(this.hash);
                                        this.s = 2; // prevent saving
                                }
                        }
                }
        };
        setRating(rating: number) {
                if (this.ownerRating !== rating) {
                        this.s = 0;
                        if (this.votes) {
                                this.votes[this.ownerRating - 1]--;
                                this.votes[rating - 1]++;
                        }
                        this.ownerRating = rating;
                        if (this.pub > 1) {
                                const p = this.getPage();
                                if (p) {
                                        this.parents.forEach(id => {
                                                if (p.opinions[id]) {
                                                        const oParent = p.opinions[id];
                                                        if (oParent.s >= 2) {
                                                                // Make sure not to reload from server with old child rating
                                                                oParent.s = Date.now();
                                                        }
                                                }
                                        });
                                }
                        }
                }
        };
        getPage(): OpinionPageStore {
                return null;
        };
        needsSave(): boolean {
                const shouldSave = this.pub && this.pub > 1;
                const isSaved = this.s && this.s > 0;
                return shouldSave && !isSaved;
        };
        selfData(): Array<any> {
                if (this.was) {
                        return ['restate', this.was, this.ownerRating, this.pub, this.statement];
                } else {
                        return ['put_opinion', this.ownerRating, this.pub, this.statement];
                }
        };
        relData(): Array<Array<any>> {
                let rel = 'pro';
                let results = [];
                // FIXME: opinions[id] is undefined
                const helper = (id: string, index: number) => this.getPage().opinions[id]?.pub > 1 && [
                        'put_rel',
                        this.pub,
                        this.statement,
                        rel,
                        this.getPage().opinions[id].statement,
                        index
                ];
                results = this.pro.filter(x => x).map(helper);
                rel = 'con';
                results = results.concat(this.con.map(helper));
                return results.filter(x => x);
        };
}

export class OpinionPageStore {
        judge: Judge;
        opinions: { [key: string]: OpinionStore };
        deletions: string[];
        main: string;
        getMain(): OpinionStore {
                return this.opinions[this.main];
        };
        deleteMain(): void {
                const o = this.opinions[this.main];
                delete this.opinions[this.main];
                Object.keys(this.opinions).forEach(key => {
                        const o2 = this.opinions[key];
                        if (this.isMine(o2)) {
                                if (o2.statement === o.statement) {
                                        delete this.opinions[key];
                                } else {
                                        const pros = o2.pro.length;
                                        const cons = o2.con.length;
                                        o2.pro = o2.pro.filter(id => id !== this.main);
                                        o2.con = o2.con.filter(id => id !== this.main);
                                        if (o2.pro.length < pros || o2.con.length < cons) {
                                                if (o2.pub && o2.pub > 1) {
                                                        o.s = 0;
                                                }
                                        }
                                }
                        }
                });
                this.main = '';
                if (o.pub > 1 && o.hash && o.hash.length === 32) {
                        this.deletions.push(o.hash);
                        this.save();
                }
        };
        getFullPath(): string {
                return this.getMain().getFullPath();
        };
        getLoginPath(): string {
                return `/i/?next=${encodeURIComponent(this.getFullPath())}`;
        };
        getPro(): Array<OpinionStore> {
                return this.getMain().pro.map(id => this.opinions[id]);
        };
        getCon(): Array<OpinionStore> {
                return this.getMain().con.map(id => this.opinions[id]);
        };
        addRelated(rel: 'pro' | 'con', statement: string) {
                const oMain = this.getMain();
                if (this.isMine(oMain)) {
                        let o = new OpinionStore(statement);
                        const id = this.myHash(o) || o.id;
                        if (this.opinions[id]) {
                                o = this.opinions[id];
                        } else {
                                o.getPage = () => this;
                                this.opinions[o.id] = o;
                                // setPub will send to server if applicable
                                o.setPub(oMain.pub || 1, this.judge);
                        }
                        const related = oMain[rel];
                        const i = related.indexOf(o.id);
                        if (i >= 0) {
                                related.splice(i, 1);
                        }
                        related.push(o.id);
                        oMain[rel] = related.filter(x => x);
                        o.parents.push(oMain.id);
                        oMain.s = 0;
                        if (oMain.pub > 1) {
                                oMain.parents.forEach(id => {
                                        if (this.opinions[id]) {
                                                const oParent = this.opinions[id];
                                                if (oParent.s >= 2) {
                                                        // Make sure not to reload from server with old pro/con count
                                                        oParent.s = Date.now();
                                                }
                                        }
                                });
                        }
                        this.save();
                }
        };
        unlinkRelated(rel: 'pro' | 'con', id: string) {
                if (this.isMine(this.getMain())) {
                        const mainOpinion = this.getMain()
                        const related = mainOpinion[rel];
                        const relationshipId = this.relId(mainOpinion.hash, rel, id);
                        const i = related.indexOf(id);
                        if (i >= 0) {
                                related.splice(i, 1);
                        }
                        if (this.opinions[id] &&
                                mainOpinion.pro.indexOf(id) < 0 &&
                                mainOpinion.con.indexOf(id) < 0) {
                                const j = this.opinions[id].parents.indexOf(mainOpinion.id);
                                if (j >= 0) {
                                        this.opinions[id].parents.splice(j, 1);
                                }
                        }
                        if (relationshipId) {
                                this.deletions.push(relationshipId);
                        }
                        mainOpinion.s = 0;
                        this.save();
                }
        };
        add(statement: string): OpinionStore {
                let o = new OpinionStore(statement);
                if (this.opinions[o.id]) {
                        o = this.opinions[o.id];
                } else {
                        o.getPage = () => this;
                        o.s = 0;
                        this.opinions[o.id] = o;
                }
                return o;
        };
        save(): Promise<any> {
                localStorage.setItem('o', JSON.stringify(this));
                if (this.judge && this.judge.hasPremium()) {
                        const dirty = Object.values(this.opinions).filter((o: OpinionStore) =>
                                o.needsSave());
                        let oData = dirty.map((o: OpinionStore) => o.selfData());
                        dirty.forEach((o: OpinionStore) => {
                                oData = oData.concat(o.relData());
                        });
                        oData = oData.concat(this.deletions.map(id => ['del', id]));
                        if (oData.length) {
                                const resolve = (data) => {
                                        let newRoute = '';
                                        for (let i = 0; i < dirty.length; i++) {
                                                if (data[i].id) {
                                                        const hash = data[i].id;
                                                        delete dirty[i].was;
                                                        // Make sure not to reload stale version
                                                        dirty[i].s = Date.now();
                                                        dirty[i].hash = hash;
                                                        this.opinions[hash] = dirty[i];
                                                        const oldId = dirty[i].id;
                                                        if (oldId !== hash) {
                                                                dirty[i].id = hash;
                                                                dirty[i].parents.forEach(id => {
                                                                        if (this.opinions[id]) {
                                                                                const o = this.opinions[id];
                                                                                if (o.s > 1) {
                                                                                        // making sure to fetch updated version
                                                                                        o.s = dirty[i].s;
                                                                                }
                                                                                for (let i = 0; i < o.pro.length; i++) {
                                                                                        if (o.pro[i] === oldId) {
                                                                                                o.pro[i] = hash;
                                                                                        }
                                                                                }
                                                                                for (let i = 0; i < o.con.length; i++) {
                                                                                        if (o.con[i] === oldId) {
                                                                                                o.con[i] = hash;
                                                                                        }
                                                                                }
                                                                        }
                                                                });
                                                                if (oldId === this.main) {
                                                                        newRoute = dirty[i].getFullPath();
                                                                }
                                                        }
                                                }
                                        }
                                        // Process deletions
                                        if (data.length > dirty.length) {
                                                for (let i = dirty.length; i < data.length; i++) {
                                                        if (data[i].id) {
                                                                this.deletions = this.deletions.filter(id => id !== data[i].id);
                                                        }
                                                }
                                        }
                                        if (newRoute) {
                                                m.route.set(newRoute, null, { replace: true });
                                        }
                                };
                                const reject = (data) => {
                                        dirty.forEach((o: OpinionStore) => {
                                                o.s = 0;
                                        });
                                };
                                dirty.forEach((o: OpinionStore) => {
                                        o.s = 1;
                                });
                                const postData = {
                                        bearer: this.judge.bearer,
                                        changes: oData
                                };
                                const retval = m.request({
                                        method: 'POST',
                                        url: '/w',
                                        body: postData
                                }).then(resolve, reject);
                                return retval;
                        }
                }
                return Promise.resolve();
        };
        clearPendingSave() {
                Object.values(this.opinions).forEach((o: OpinionStore) => {
                        if (o.s === 1) {
                                o.s = 0;
                        }
                });
        };
        overlay(obj: object): void {
                this.opinions = Object.assign(this.opinions, obj['opinions']);
                Object.keys(this.opinions).forEach(key => {
                        this.opinions[key] = Object.assign(
                                new OpinionStore(''),
                                this.opinions[key]
                        );
                        this.opinions[key].getPage = () => this;
                });
                if (obj['deletions'] instanceof Array) {
                        obj['deletions'].forEach(id => {
                                if (id) {
                                        delete this.opinions[id];
                                }
                        });
                }
        };
        setMainFromPath(path: string, hash?: string) {
                if (!path) {
                        this.opinions[''] = new OpinionStore('');
                        this.opinions[''].getPage = () => this;
                        this.main = '';
                } else {
                        if (hash?.length === 32) {
                                const existingOpinion = this.opinions[hash];
                                const saveStatus = existingOpinion && existingOpinion.s;
                                if (!existingOpinion) {
                                        this.opinions[hash] = new OpinionStore(path);
                                }
                                this.main = hash;
                                // Load unless unsaved/saving
                                if (!(saveStatus === 0 || saveStatus === 1)) {
                                        m.request({
                                                method: 'GET',
                                                params: saveStatus === 2 ? undefined : { s: saveStatus },
                                                url: `/r/o/${hash}`
                                        }).then((opinions: ServerOpinion[]) => {
                                                /* If all is the same as when we requested, load main from response */
                                                if (this.main === hash &&
                                                        (!existingOpinion ||
                                                                existingOpinion.s === saveStatus)) {
                                                        opinions.forEach(([truth, pub, rel, place, id, statement, proCount, conCount, votes]) => {
                                                                if (rel === 'one') {
                                                                        const mainOpinion = this.opinions[hash] || new OpinionStore(statement);
                                                                        mainOpinion.getPage = () => this;
                                                                        mainOpinion.statement = statement;
                                                                        mainOpinion.id = mainOpinion.hash = hash;
                                                                        this.opinions[hash] = mainOpinion;
                                                                        mainOpinion.ownerRating = truth;
                                                                        mainOpinion.pub = pub;
                                                                        mainOpinion.votes = votes;

                                                                        const local = (rels: string[]) => rels.filter(rel => rel && this.opinions[rel] && (this.opinions[rel].pub <= 1 || this.opinions[rel].needsSave()));
                                                                        mainOpinion.pro = local(mainOpinion.pro);
                                                                        mainOpinion.con = local(mainOpinion.con);
                                                                        this.main = hash;
                                                                } else {
                                                                        const relatedOpinion = this.receiveServerOpinion([truth, pub, rel, place, id, statement, proCount, conCount, votes]);
                                                                        if (relatedOpinion.parents.indexOf(hash) < 0) {
                                                                                relatedOpinion.parents.push(hash);
                                                                        }
                                                                        this.opinions[hash][rel][place] = id;
                                                                }
                                                        });
                                                        // Ensure other windows see newly loaded opinions
                                                        this.save();
                                                }
                                        });
                                }
                        } else if (this.opinions[path]) {
                                this.main = path;
                        }
                }
        };
        constructor(statement: string) {
                const o = new OpinionStore(statement);
                o.getPage = () => this;
                this.opinions = {};
                this.deletions = [];
                this.opinions[o.id] = o;
                this.main = o.id;
        };
        myHash(o: OpinionStore) {
                if (this.judge && this.judge.privateId) {
                        return Md5.hashStr(this.judge.privateId.replace(/-/g, '') + Md5.hashStr(o.statement)).toString();
                }
                return '';
        };
        isMine(o: OpinionStore) {
                if (!o || o.hash === 'i') {
                        return true;
                }
                if (o.hash.length === 32) {
                        return o.hash === this.myHash(o);
                }
                return false;
        };
        getMine(o: OpinionStore) {
                if (this.isMine(o)) {
                        return o;
                }
                const hash = this.myHash(o);
                if (hash && this.opinions[hash]) {
                        return this.opinions[hash];
                }
                return this.opinions[o.statement.replace(/[\s,\.\/;&\?\"]+/g, '_').toLowerCase()];
        };
        getUserRating(o: OpinionStore) {
                const oMine = this.getMine(o);
                return oMine && oMine.ownerRating;
        };
        setUserRating(o: OpinionStore, rating: number) {
                let oMine = this.getMine(o);
                if (!oMine) {
                        oMine = new OpinionStore(o.statement);
                        oMine.pub = o.pub;
                        this.opinions[oMine.id] = oMine;
                }
                oMine.setRating(rating);
        };
        relId(hash1: string, rel: string, hash2: string): string {
                if (this.judge && this.judge.privateId && hash1.length === 32 && hash2.length === 32) {
                        return Md5.hashStr(this.judge.privateId.replace(/-/g, '') + hash1 + rel + hash2).toString();
                } else {
                        return '';
                }
        };
        getAllOpinions(): string[] {
                return Array.from(
                        new Set(
                                Object.keys(this.opinions).filter(x => x).map(key =>
                                        this.opinions[key].statement))).filter(str => (str && !str.startsWith('http:') && !str.startsWith('https:'))).sort();
        };

        receiveServerOpinion([truth, pub, _rel, _place, id, statement, proCount, conCount, votes]: ServerOpinion): OpinionStore {
                const rcvdOpinion = this.opinions[id] || new OpinionStore(statement);
                rcvdOpinion.getPage = () => this;
                rcvdOpinion.id = id;
                rcvdOpinion.hash = id;
                rcvdOpinion.ownerRating = truth;
                rcvdOpinion.pub = pub;
                rcvdOpinion.votes = votes;
                for (let i = 0; i < proCount; i++) {
                        if (rcvdOpinion.pro[i] === undefined) {
                                rcvdOpinion.pro[i] = '';
                        }
                }
                for (let i = proCount; i < rcvdOpinion.pro.length; i++) {
                        if (rcvdOpinion.pro[i] === '') {
                                delete rcvdOpinion.pro[i];
                        }
                }
                for (let i = 0; i < conCount; i++) {
                        if (rcvdOpinion.con[i] === undefined) {
                                rcvdOpinion.con[i] = '';
                        }
                }
                for (let i = conCount; i < rcvdOpinion.con.length; i++) {
                        if (rcvdOpinion.con[i] === '') {
                                delete rcvdOpinion.con[i];
                        }
                }
                this.opinions[id] = rcvdOpinion;
                return rcvdOpinion;
        };
        receiveTopLevelOpinions(): void {
                m.request({
                        method: 'GET',
                        url: '/d/it'
                }).then((opinions: ServerOpinion[]) => {
                        opinions.forEach(
                                opinion => this.receiveServerOpinion(opinion));
                });
        };
}
