export class Judge {
    bearer: string;
    premium: number;
    privateId: string;
    k?(): number;
    hasPremium(): boolean {
        return this.premium && this.premium > 0;
    };
    logout() {
        delete this.bearer;
        delete this.premium;
    }
}
